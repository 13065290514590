import io, { Socket } from 'socket.io-client';

const API_HOST: string = process.env.REACT_APP_API_HOST ?? 'http://localhost';
const API_PORT: string = process.env.REACT_APP_API_PORT ?? '3000';

const API_URL: string = API_HOST + ':' + API_PORT;

export let gameSocket: Socket | null;

export const connectSockets = () => {

    if (!gameSocket) {
        gameSocket = io(API_URL + '/v1/game', {
            path: '/ws',
            withCredentials: false,
            reconnectionAttempts: 5,
            reconnectionDelay: 4000,
            transports: ['websocket', 'polling']
        });

        gameSocket.on('connect', () => {
            console.log('GameSocket conectado');
        });

        gameSocket.on('error', (error) => {
            console.log(`GameSocket error: ${error}`);
        });

        gameSocket.on('disconnect', () => {
            console.log('GameSocket desconectado');
        });
    }
}

export const disconnectSockets = () => {

    if (gameSocket) {
        gameSocket.disconnect();
        gameSocket.removeAllListeners();
        gameSocket = null;
    }

}