import ReactDOM from 'react-dom/client';
import 'bootstrap/dist/css/bootstrap.min.css';
import './index.css';
import App from './App';

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);
root.render(<App />);

// Usar este root.render() para desarrollo
// root.render(
//   <React.StrictMode>
//     <App />
//   </React.StrictMode>
// );